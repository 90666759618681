<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4" v-html="message"></v-card-text>
      <v-card-actions class="pt-0">
        <v-form ref="form" v-model="valid">
          <v-text-field :rules="[rules.req]" label="p. ej. Decoración extra" v-model="objTitle"></v-text-field>
        </v-form>
        <v-btn color="primary darken-1" text @click.native="agree">Añadir</v-btn>
        <v-btn color="grey" text @click.native="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  data: () => ({
    rules: { req },
    valid: true,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    objTitle: "",
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (this.$refs.form.validate()){
        this.resolve(this.objTitle.toUpperCase())
        this.dialog = false
        this.objTitle = ""
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>